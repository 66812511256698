import React from "react"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"

import './termin.sass'
import {graphql} from "gatsby";

const TerminPage = ({data}) => {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { html, frontmatter } = markdownRemark

    return (
        <Layout>
            <SEO title={ frontmatter.title }/>
            <div className={'terminPage container section bottom'}>

                <h1 className={'title'}>Termin</h1>
                <h4 className={'subtitle'}>{ frontmatter.title }
                </h4>
                <div className="text-container left">
                    <div className="time">
                        <img src="/images/time.svg" alt=""/>
                        <h6>{ frontmatter.time }</h6>
                    </div>
                    <div className="place">

                        <img src="/images/place.svg" alt=""/>
                        <p>{ frontmatter.location }</p>
                    </div>
                    <p className="terminPage--body" dangerouslySetInnerHTML={{__html: html}} />
                    <a href="/termine" className={'secondary-button'}>Alle Termine</a>
                </div>
            </div>
        </Layout>
    )
};

export default TerminPage

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        time
        location
      }
    }
  }
`;
